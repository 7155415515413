<template>
  <div class="body">
    <div class="initBox">
      <templateHeader />
      <templateNavbar/>
      <div class="main">
        <router-view :key="$route.fullPath"></router-view>
      </div>
      <templateFooter />
    </div>
  </div>
</template>

<script>
import templateHeader from "./templateHeader";
import templateFooter from "./templateFooter";
import templateNavbar from "./templateNavbar";
// import Index from "./index";
export default {
  name: "Home",
  components: {
    templateHeader,
    templateFooter,
    templateNavbar
    // Index
  },
  data() {
    return {};
  },

  mounted() {
    let _token = JSON.stringify(this.$route.query.taken);
    let _tenantId = JSON.stringify(this.$route.query.domainTid);
    if (_token) {
      window.localStorage.setItem("token", _token);
    }
    if(_tenantId){
      window.localStorage.setItem("domainTid", _tenantId);
    }
  },
};
</script>
<style lang="scss" scoped>
  .body{
    //background-color: #f4f4f4;
    color: #5c5c5c;
    //background-image: linear-gradient( #CCF7FC, #ffffff);
    font-family: "微软雅黑";
    font-size: 14px;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background: url("../images/eighth_bg2.png") center top no-repeat #efefef;
    background-size:100% 100%;
  }
  .initBox{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
  }
  .main{
    width: 1230px;
    margin: 0 auto;
    color: #333;

    //padding: 15px;
    //background: rgba(255,255,255,0.6)
  }
</style>
